





































































































































































import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import opt from "@/data/options";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { logic } from "@ldvsg/shared";
import store from "@/store";
import * as profileConfig from "@profile/config"

export default Vue.extend({
  data() {
    return {
      lighting: cloneDeep(this.$store.state.project.lighting),
      profileConfig
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        lighting: cloneDeep(this.lighting),
      });
      this.$store.commit("setUnsavedChanges", false);
    },
    fieldAmount() {
      return this.chevrons - 1;
    },
    spotAmount() {
      return logic.ledSpots(this.depth!, this.chevrons)
    },
    checkIfAdditionalNeeded() {
      if (["powerplastics", "powerglass"].includes(this.lighting.variant)) {
        this.lighting.additionalLighting = true;
      } else {
        this.lighting.additionalLighting = false;
      }
    },
    async downloadPreview(type: string) {
      const pdf: Blob = await this.$store.dispatch("getPreview", type)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(pdf)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
  computed: {
    width: () => store.state.project?.configuration.width,
    depth: () => store.state.project?.configuration.depth,
    roofing: () => store.state.project?.configuration.roofingVariant,
    chevrons: () => store.getters.chevrons,
    triangleHeight: () => store.getters.triangleHeight,
    fieldWidth: () => store.getters.fieldWidth,
    fieldDepth: () => store.getters.fieldDepth,

    boolOptions: function () {
      return opt.boolOptions;
    },
    lightingVariantOptions() {
      return opt.lightingVariantOptions
    },
    lightingVariantOptionsAdditional: function () {
      return opt.lightingVariantOptionsAdditional;
    },
    unit(): string {
      if (this.lighting.variant == "powerglass") return "Quadratmeter";
      else return "Anzahl";
    },
  },
  created() {
    this.$watch(() => {
      switch (this.lighting.variant) {
        case "powerglass":
          this.lighting.amount = Math.round(logic.powerglass(
            this.fieldWidth,
            this.fieldDepth,
            this.lighting.powerLightingFields.filter((field: boolean) => field).length
          ) * 100) / 100
          break
        case "powerplastics":
          this.lighting.amount = logic.powerplastics(
            this.lighting.powerLightingFields.filter((field: boolean) => field).length
          )
          break
        case "led_spots":
          this.lighting.amount = logic.ledSpots(this.depth!, this.chevrons)
          this.lighting.additionalAmount = 0
          break
      };
    }, () => { })
    this.$watch(() => {
      this.lighting.additionalVariant
      if (this.lighting.additionalVariant == null) {
        this.lighting.additionalAmount = 0
      }
    }, () => { })
    this.$watch(
      () => this.lighting,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
    let placeholder = { disabled: true }
    if (["vsg_klar", "vsg_milch"].includes(this.roofing!)) {
      (this.lightingVariantOptions.find(
        (opt) => opt.value == "powerplastics"
      ) ?? placeholder).disabled = true;
      (this.lightingVariantOptions.find(
        (opt) => opt.value == "powerglass"
      ) ?? placeholder).disabled = false;
    } else if (["poly_klar", "poly_opal", "poly_ir"].includes(this.roofing!)) {
      (this.lightingVariantOptions.find(
        (opt) => opt.value == "powerplastics"
      ) ?? placeholder).disabled = false;
      (this.lightingVariantOptions.find(
        (opt) => opt.value == "powerglass"
      ) ?? placeholder).disabled = true;
    }
    while (this.lighting.powerLightingFields.length > this.fieldAmount()) {
      this.lighting.powerLightingFields.pop();
    }
  },
  async beforeRouteLeave(to, _from, next) {
    if (!["Projects", "CustomerInformation", "GeneralData", "Awning"].includes(to.name ?? "") && !(await (this.$refs.form as any).validate()))
      return;
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },
});
