




































































































import Vue from "vue"
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import { fetchAPI } from "@/store/api"
import options from "@/data/options"

export default Vue.extend({
  data() {
    return {
      codes: [] as Array<any>,
      newCode: {
        market: [],
        value: "",
        text: "",
        amount: 0,
        active: true
      }
    }
  },
  components: { ValidationObserver, ValidatedFormField, ValidationProvider },
  computed: {
    marketOptions() {
      return options.marketOptions
    }
  },
  methods: {
    openAddModal() {
      this.$bvModal.show("addCode")
    },
    concatMarkets(item: any) {
      let res = ""
      item.market.forEach((code: string) => {
        res += `${code}, `
      });
      return res
    },
    async uploadCode() {
      if(!await (this.$refs.form as any).validate()) return
      await fetchAPI("/api/promo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.newCode)
      })
      this.codes = await this.$store.dispatch("getPromoCodes", true)
      this.$bvModal.hide("addCode")
    },
    async deleteCode(value: string) {
      await fetchAPI(`/api/promo/${value}`, {
        method: "DELETE",
      })
      this.codes = await this.$store.dispatch("getPromoCodes", true)
    },
    async updateCode(value: string) {
      const code = this.codes.find(el => el.value === value)
      await fetchAPI(`/api/promo/${value}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ active: !code.active })
      })
      this.codes = await this.$store.dispatch("getPromoCodes", true)
    }
  },
  async created() {
    this.codes = await this.$store.dispatch("getPromoCodes", true)
  }
})
