

































































































































































































































































































































import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import { logic } from "@ldvsg/shared";
const ral = require("@/data/ralColor.json");
import cloneDeep from "lodash/cloneDeep";
import options from "@/data/options";
import store from "@/store";
import { defaultProject } from '@ldvsg/shared'
import * as profileConfig from "@profile/config"
import { extend } from 'vee-validate';

export default Vue.extend({
  components: { ValidationObserver, ValidatedFormField, ValidationProvider },
  data() {
    return {
      dismissCountDown: 5 as number,
      showDepthAlert: false,
      configuration: cloneDeep(store.state.project!.configuration),
      customer: cloneDeep(store.state.project.customer),
      project: cloneDeep(store.state.project),
      tempColor: null as number | null,
      tempCustomColor: null as number | null,
      unsavedChanges: false,
      profileConfig
    }
  },
  computed: {
    fieldWidth(): number | null {
      const { width, roofingVariant } = this.configuration
      if (!width || !roofingVariant) return null
      return logic.roofFieldWidth(width, logic.chevronCount(width, roofingVariant))
    },
    fieldSize(): string {
      const { width, depth, heightGutter, heightWall } = this.configuration
      if (!width || !depth || !heightGutter || !heightWall || !this.fieldWidth) return ""
      const fieldLength = logic.roofFieldDepth(depth)
      return `${this.fieldWidth.toFixed()} x ${fieldLength.toFixed()}`
    },
    minDepth(): number {
      return profileConfig.config.minDepth
    },
    maxDepth(): number {
      return logic.isGlassRoof(this.configuration.roofingVariant!)
        ? profileConfig.config.maxDepthGlass
        : profileConfig.config.maxDepthPoly;
    },
    maxWidth(): number {
      return profileConfig.config.maxWidth
    },
    minWidth(): number {
      return profileConfig.config.minWidth
    },
    colorOptions() {
       return options.color
    },
    roofingOptions() {
      return options.roofing
    },
    unterzugOptions() {
      const t = logic.unterzugType(this.configuration.width, this.configuration.roofingVariant)
      return options.unterzugOptions.filter(it => it.value == t || it.value == 4 && t == 3)
    },
    postVariantOptions: () => options.postVariant,
    cssColor(): string {
      const { colorCode } = this.configuration;
      return colorCode! in ral ? ral[colorCode!] : "#F6F6F6"
    },
    roofPitchDegree(): number | null {
      const { depth, heightGutter, heightWall } = this.configuration
      if (!depth || !heightGutter || !heightWall) return null
      return logic.roofPitchDegree(depth, heightGutter, heightWall)
    },
    drainages(): number | null {
      const { width, depth, heightGutter, heightWall } = this.configuration
      if (!width || !depth || !heightGutter || !heightWall) return null
      return logic.drainages(width, depth, heightGutter, heightWall)
    },
    steelSupports(): number | null {
      return this.configuration.postCount
    },
    chevronCount(): number | null {
      const { width, roofingVariant } = this.configuration
      if (!width || !roofingVariant) return null
      return logic.chevronCount(width, roofingVariant)
    },
    tooSteep(): boolean {
      return this.roofPitchDegree != null && this.roofPitchDegree > 10
    },
    tooShallow(): boolean {
      const min = logic.isGlassRoof(this.configuration.roofingVariant!) ? 3 : 5
      return this.roofPitchDegree != null && this.roofPitchDegree < min
    },
    redundantPost(): boolean {
      return this.configuration.width! - this.configuration.postOffsets![0]
        + this.configuration.postOffsets![this.configuration.postOffsets!.length - 1]
        <= this.maxDepth
    },
    useUnterzug(): boolean {
      return profileConfig.modules.useUnterzug && logic.unterzug(this.configuration.depth, this.configuration.roofingVariant)
    },
  },
  methods: {
    countDownChanged(dismissCountDown: number) {
      this.dismissCountDown = dismissCountDown
    },
    setMaxDepthForRoofingVariant(roofingVariant: string) {
      if (logic.isGlassRoof(roofingVariant)) {
        if (this.configuration.depth! > profileConfig.config.maxDepthGlass) {
          this.configuration.depth = profileConfig.config.maxDepthGlass;
          this.showDepthAlert = true
        }
      }
    },
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        configuration: cloneDeep(this.configuration),
        awning: {
          ...this.$store.state.project.awning,
          ...(this.configuration.postVariant == "abgerundet"
            ? { mountingPostPosition: "between_posts" }
            : null),
        },
        lighting: {
          ...this.$store.state.project.lighting,
          ...(["vsg_klar", "vsg_milch"].includes(this.configuration.roofingVariant!) &&
            this.$store.state.project.lighting.variant == "powerplastics"
            ? { variant: "powerglass" }
            : null),
          ...(["poly_ir", "poly_opal", "poly_klar"].includes(
            this.configuration.roofingVariant!
          ) && this.$store.state.project.lighting.variant == "powerglass"
            ? { variant: "powerplastics" }
            : null),
        },
      });
      this.$store.commit("setUnsavedChanges", false);
    },
    maxOffsetsForInsidePosts(idx: number, left: boolean) {
      const defaultPostWidth = logic.sidePanelWidth(this.configuration.width ?? 0, this.configuration.postCount ?? 2)
      const maxPostWidth = logic.isGlassRoof(this.configuration.roofingVariant ?? "") ? 4000 : 5000
      if (left) {
        const diff = defaultPostWidth + this.configuration.postOffsets[idx + 1]
        return -(maxPostWidth - diff)
      } else {
        const diff = defaultPostWidth - this.configuration.postOffsets[idx - 1]
        return maxPostWidth - diff
      }
    },
    offsetLeft(idx: number): number {
      switch (idx) {
        case 0:
          return 0
        case this.configuration.postOffsets!.length - 1:
          return -(this.fieldWidth || 0)
        default:
          return this.maxOffsetsForInsidePosts(idx, true)
      }
    },
    offsetRight(idx: number): number {
      switch (idx) {
        case 0:
          return this.fieldWidth || 0
        case this.configuration.postOffsets!.length - 1:
          return 0
        default:
          return this.maxOffsetsForInsidePosts(idx, false)
      }
    },
    async resetAllAwningSettingsIfGeneralDataChanges() {
      await this.$store.dispatch("updateProject", {
        ...defaultProject,
        id: this.project.id,
        schemaVersion: this.project.schemaVersion,
        market: this.project.market,
        retrievalNumber: this.project.retrievalNumber,
        status: this.project.status,
        customer: this.customer,
        configuration: this.configuration
      })
    },
    async downloadPreview(type: string) {
      const pdf: Blob = await this.$store.dispatch("getPreview", type)
      const a = document.createElement("a")
      a.href = URL.createObjectURL(pdf)
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
  async mounted() {
    this.$root.$on('bv::modal::show', async (bvEvent: any, modalId: string) => {
      try {
        await this.save()
      } catch (e) {
        console.log(e)
      }
    })
  },
  created() {
    extend('roofPitch',  {
      validate: value => { return !this.tooSteep && !this.tooShallow },
      message: "Dachgefälle ist ungültig"
    })
    this.unsavedChanges = false
    this.$watch(() => {
      const { width, roofingVariant, postOffsets } = this.configuration

      this.configuration.postCount = width && roofingVariant
        ? logic.postCount(width, roofingVariant) + (this.configuration.additionalPostCount ?? 0)
        : null

      if (this.configuration.postCount != null) {
        while (postOffsets.length < this.configuration.postCount) {
          postOffsets.push(0)
        }

        while (postOffsets.length > this.configuration.postCount) {
          postOffsets.pop()
        }
      }

    }, () => { })

    this.$watch(() => {
      const { depth, roofingVariant } = this.configuration

      if (logic.unterzug(depth, roofingVariant)) {
        const t = logic.unterzugType(this.configuration.width, roofingVariant)
        if (!(this.configuration.unterzugType == 4 && t == 3)) this.configuration.unterzugType = t
      } else this.configuration.unterzugType = 0
    }, () => {})

    if (this.configuration.isCustomColor) {
      this.tempColor = 0
      this.tempCustomColor = this.configuration.colorCode
    } else {
      this.tempColor = this.configuration.colorCode
    }
    this.$watch(() => this.tempColor, () => {
      if (this.tempColor === 0) {
        this.configuration.isCustomColor = true
        this.$watch(() => this.tempCustomColor, () => {
          this.configuration.colorCode = this.tempCustomColor
        })
      } else {
        this.configuration.isCustomColor = false
        this.configuration.colorCode = this.tempColor
        this.configuration.colorHasStructure = this.tempColor == 9016 ? false : true
      }
    })

    this.$watch(() => this.configuration, () => {
      this.$store.commit("setUnsavedChanges", true);
      this.unsavedChanges = true
    }, { deep: true });
  },
  async beforeRouteLeave(to, _from, next) {
    if (!["Projects", "CustomerInformation"].includes(to.name ?? "") && !(await (this.$refs.form as any).validate()))
      return;
    try {
      await this.save();
      if (this.unsavedChanges) await this.resetAllAwningSettingsIfGeneralDataChanges();
    } catch (e) {
      console.log(e);
    }
    next();
  },
});
