var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Steuerung"}},[_c('b-form-select',{attrs:{"options":_vm.controlOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awningComplete.controls),callback:function ($$v) {_vm.$set(_vm.awningComplete, "controls", $$v)},expression:"awningComplete.controls"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motorseite"}},[_c('b-form-select',{attrs:{"options":_vm.awningEngineSideOptions,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awning.engineSide),callback:function ($$v) {_vm.$set(_vm.awning, "engineSide", $$v)},expression:"awning.engineSide"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.activeTab != 0)?_c('b-col',[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Markisenmontage"}},[(_vm.activeTab == 1)?_c('b-form-select',{attrs:{"disabled":_vm.activeTab == 2,"options":_vm.mountingPostPosition,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awningComplete.mountingPostPosition),callback:function ($$v) {_vm.$set(_vm.awningComplete, "mountingPostPosition", $$v)},expression:"awningComplete.mountingPostPosition"}}):_c('b-form-select',{attrs:{"disabled":"","options":_vm.mountingPostPosition,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.awningSide.mountingPostPosition),callback:function ($$v) {_vm.$set(_vm.awningSide, "mountingPostPosition", $$v)},expression:"awningSide.mountingPostPosition"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,395952815)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Maß in mm"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.awningSize}})],1)],1)],1),(_vm.awningComplete.mountingPostPosition == 'between_posts' && _vm.activeTab != 0)?_c('b-alert',{staticClass:"mt-3",attrs:{"show":"","dismissible":"","variant":"warning"}},[_vm._v(" Bei Montagen zwischen den Pfosten sind nachträgliche Unterbau Elemente nicht mehr möglich. ")]):_vm._e(),(
      _vm.awningComplete.mountingPostPosition == 'on_posts' &&
      _vm.postVariant == 'abgerundet' &&
      _vm.activeTab != 0
    )?_c('b-alert',{staticClass:"mt-3",attrs:{"show":"","dismissible":"","variant":"warning"}},[_vm._v("Pfostenvariante wurde auf Eckig geändert.")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }