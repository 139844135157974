

























import Vue from 'vue'
import { logoSrc } from "@profile/config"

export default Vue.extend({
  data() {
    return {
      override: false,
      logoSrc: logoSrc
    }
  },
  computed: {
    page() {
      return this.$route.name;
    },
    isAuthorized() {
      return this.$auth.user && this.$auth.user.roles["Konfigurator"]?.includes('manager');
    }
  }
})
