var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"disable-all",rawName:"v-disable-all",value:(_vm.$store.getters.projectDisabled),expression:"$store.getters.projectDisabled"}],staticClass:"container my-4"},[_c('h2',[_vm._v("Beleuchtung und Soundsystem")]),_c('ValidationObserver',{ref:"form"},[_c('b-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Möchten Sie Licht?"}},[_c('b-input-group',[_c('b-form-radio-group',{attrs:{"options":_vm.boolOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.lighting),callback:function ($$v) {_vm.$set(_vm.lighting, "lighting", $$v)},expression:"lighting.lighting"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))]),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadPreview('lighting')}}},[_vm._v("Beispiel")])],1)],1)],1)]}}])}),(_vm.lighting.lighting)?[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Variante"}},[_c('b-form-select',{attrs:{"options":_vm.lightingVariantOptions,"state":errors[0] ? false : valid ? undefined : null},on:{"change":_vm.checkIfAdditionalNeeded},model:{value:(_vm.lighting.variant),callback:function ($$v) {_vm.$set(_vm.lighting, "variant", $$v)},expression:"lighting.variant"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3252444012)})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.unit}},[_c('b-form-input',{staticStyle:{"text-align":"center"},attrs:{"disabled":"","type":"number"},model:{value:(_vm.lighting.amount),callback:function ($$v) {_vm.$set(_vm.lighting, "amount", $$v)},expression:"lighting.amount"}})],1)],1)],1),(_vm.lighting.variant == 'powerplastics'
            || _vm.lighting.variant == 'powerglass')?[_c('b-row',{staticClass:"mb-5"},[_vm._l((_vm.fieldAmount()),function(item,index){return _c('b-col',{key:item},[_c('b-checkbox',{model:{value:(_vm.lighting.powerLightingFields[index]),callback:function ($$v) {_vm.$set(_vm.lighting.powerLightingFields, index, $$v)},expression:"lighting.powerLightingFields[index]"}},[_vm._v(_vm._s("Feld " + "" + item))])],1)}),_c('b-col',{attrs:{"cols":"3"}})],2)]:_vm._e(),(_vm.lighting.additionalLighting)?_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Zusätzliche Beleuchtung"}},[_c('b-form-select',{attrs:{"options":_vm.lightingVariantOptionsAdditional,"state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.additionalVariant),callback:function ($$v) {_vm.$set(_vm.lighting, "additionalVariant", $$v)},expression:"lighting.additionalVariant"}}),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1982846349)})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Anzahl"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{attrs:{"disabled":_vm.lighting.additionalVariant == null || _vm.lighting.additionalAmount <= 0},on:{"click":function($event){_vm.lighting.additionalAmount--}}},[_vm._v("-")])],1),_c('b-form-input',{staticStyle:{"text-align":"center"},attrs:{"disabled":_vm.lighting.additionalVariant == null,"type":"number","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.additionalAmount),callback:function ($$v) {_vm.$set(_vm.lighting, "additionalAmount", _vm._n($$v))},expression:"lighting.additionalAmount"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":_vm.lighting.additionalVariant == null || _vm.lighting.additionalAmount >= _vm.spotAmount()},on:{"click":function($event){_vm.lighting.additionalAmount++}}},[_vm._v("+")])],1)],1),_c('b-form-invalid-feedback',{staticClass:"error-display"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,413525920)})],1)],1):_vm._e()]:_vm._e(),(_vm.profileConfig.modules.showRadiantHeater)?_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Möchten Sie Heizstrahler?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.boolOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.radiantHeater),callback:function ($$v) {_vm.$set(_vm.lighting, "radiantHeater", $$v)},expression:"lighting.radiantHeater"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1342889613)})],1),_c('b-col',{attrs:{"cols":"3"}},[(_vm.lighting.radiantHeater)?_c('b-form-group',{attrs:{"label":"Funk"}},[_c('b-form-checkbox',{model:{value:(_vm.lighting.heaterWireless),callback:function ($$v) {_vm.$set(_vm.lighting, "heaterWireless", $$v)},expression:"lighting.heaterWireless"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"3"}},[(_vm.lighting.radiantHeater)?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Anzahl"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{on:{"click":function($event){_vm.lighting.radiantHeaterAmount--}}},[_vm._v("-")])],1),_c('b-form-input',{staticStyle:{"text-align":"center","min-width":"3rem"},attrs:{"type":"number","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.radiantHeaterAmount),callback:function ($$v) {_vm.$set(_vm.lighting, "radiantHeaterAmount", $$v)},expression:"lighting.radiantHeaterAmount"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.lighting.radiantHeaterAmount++}}},[_vm._v("+")])],1)],1)],1)]}}],null,false,1255791570)})]:_vm._e()],2)],1):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var valid = ref.valid;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Möchten Sie ein Soundsystem?"}},[_c('b-input-group',[_c('b-form-radio-group',{attrs:{"options":_vm.boolOptions,"buttons":"","button-variant":"outline-primary","state":errors[0] ? false : valid ? undefined : null},model:{value:(_vm.lighting.soundSystem),callback:function ($$v) {_vm.$set(_vm.lighting, "soundSystem", $$v)},expression:"lighting.soundSystem"}}),_c('b-form-invalid-feedback',{staticClass:"error-display",attrs:{"state":errors[0] ? false : valid ? undefined : null}},[_vm._v(_vm._s(errors[0]))]),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.downloadPreview('sound')}}},[_vm._v("Beispiel")])],1)],1)],1)]}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }