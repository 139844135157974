


















import Vue from "vue"

export default Vue.extend({
  data() {
    return {
      file: null as File | null,
      showAlert: false
    }
  },
  methods: {
    async upload() {
      const reader = new FileReader()
      reader.readAsText(this.file!)
      reader.onload = async () => {
        if((await this.$store.dispatch("updateArticles", reader.result) as Response).status == 200) this.showAlert = true
      }
    },
    async download() {
      const data = await this.$store.dispatch("getArticles")
      const a = document.createElement("a")
      a.download = "Artikelliste.csv"
      a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }))
      document.body.appendChild(a)
      a.click()
      a.remove()
      URL.revokeObjectURL(a.href)
    }
  }
})
