





















import Vue from 'vue'
import FAQModal from "@/components/FAQModal.vue"
import { modules } from "@profile/config"

export default Vue.extend({
  components: { FAQModal },
  data() {
    return {
      modules
    }
  },
  methods: {
    showFAQModal() {
      this.$bvModal.show('faq')
    }
  }
})
