export const profile = "ldvsg"

export const modules = {
  showSalesman: true,
  showAdditionalPosts: true,
  showPostOffsets: true,
  showWallHeight: true,
  showSupport: false,
  showSideAwning: true,
  showFullFabricBrand: true,
  showRadiantHeater: true,
  showPromoCodes: true,
  showDeliveryPreferences: true,
  showDiscount: true,
  showStatic: false,
  showOrderButton: true,
  useRetrievalNumber: false,
  editStatus: true,
  showSalesPrice: false,
  showFaq: false,
  useUnterzug: true
}

export const options = {
  additionalRoofingOptions: [
    { "value": "vsg_milch", "text": "VSG 10mm milchig" },
  ],
  additionalColorOptions: [
    { "value": 0, "text": "Sonderfarbe RAL" }
  ],
  awningPositionOptions: [
    "roof",
    "front"
  ],
  additionalLightingOptions: [
    { "value": "powerglass", "text": "Powerglass", "disabled": false }
  ],
  paymentOptions: [
    { "value": null, "text": "Bitte wählen", "disabled": false },
    { "value": "zk1", "text": "ZK 1: 50% Anzahlung, 40% nach Produktion & Festlegung Montagetermin, 10% nach mängelfreier Montage" },
    { "value": "zk2", "text": "ZK 2: 70% Anzahlung, 20% nach Produktion & Festlegung Montagetermin, 10% nach mängelfreier Montage (2% Skonto)" },
    { "value": "zk3", "text": "ZK 3: 100% Anzahlung (3% Skonto)" }
  ],
  statusOptions: [
    { id: "newRequest", title: "Anfrage neu", projectDisabled: false },
    { id: "quoteCreated", title: "Angebot erstellt", projectDisabled: false },
    { id: "quoteSent", title: "Angebot versendet", projectDisabled: false },
    { id: "onSiteAppointment", title: "Termin vor Ort", projectDisabled: false },
    { id: "contactCustomer", title: "Kunden kontaktieren", projectDisabled: false },
    { id: "orderAccepted", title: "Auftrag akzeptiert", projectDisabled: true },
    { id: "technicalTest", title: "TECHNISCHE PRÜFUNG", projectDisabled: true },
    { id: "measurementRequired", title: "FEINAUFMAß ERFORDERLICH", projectDisabled: true },
    { id: "waiting", title: "WARTESTATUS", projectDisabled: true },
    { id: "readyToOrder", title: "BESTELLBEREIT", projectDisabled: true },
    { id: "production", title: "PRODUKTION", projectDisabled: true },
    { id: "declined", title: "ABGESAGT", projectDisabled: true },
    { id: "readyForAssembly", title: "MONTAGEBEREIT", projectDisabled: true },
    { id: "assemblyAppointment", title: "MONTAGE TERMINIERT", projectDisabled: true },
    { id: "finished", title: "FERTIGGESTELLT", projectDisabled: true },
    { id: "deleted", title: "Gelöscht", projectDisabled: true }
  ],
  awningFabricOptions: [
    { value: "nova", text: "Nova Hüppe 200" },
    { value: "nova_zip", text: "Nova Hüppe mit Zip", disabled: false }
  ],
  deliveryOptions: [
    { value: "home", text: "Anlieferung durch LDVSG (238€)" },
    { value: "pickup", text: "Selbstabholer" }
  ]
}

export const config = {
  minDepth: 1200,
  maxDepthPoly: 5000,
  maxDepthGlass: 5000,
  minWidth: 3000,
  maxWidth: 10000,
  allowWideSpan: true,
  awningDepth: 4500,
  defaultAwningWidth: false,
  retrievalRegex: /^$/
}

export function steps(step: string) {
  return true
}

export const logoSrc = "/img/icons/icon-192x192.png"
export const awningTitle = "Markise (Ansicht von Außen)"
export const retrievalNumber = "Abrufnummer"

export const authConfig = {
  VUE_APP_KEYCLOAK_URL: "https://keycloak.cekom.de",
  VUE_APP_KEYCLOAK_REALM: "ldvsg"
}