import Vue from "vue"

const listeners = new WeakMap<Element, (event: MouseEvent) => void>()

Vue.directive("click-outside", {
  bind(el, binding) {
    listeners.set(el, event => {
      if (!el.contains(document.elementFromPoint(event.clientX, event.clientY))) {
        binding.value()
      }
    })
    document.body.addEventListener("pointerdown", listeners.get(el)!, { capture: true })
  },
  unbind(el) {
    document.body.removeEventListener("pointerdown", listeners.get(el)!, { capture: true })
    listeners.delete(el)
  }
})