





































































































































































































































































































































import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Visualization from "@/components/Visualization.vue";
import AwningOptions from "./Awning/AwningOptions.vue";
import cloneDeep from "lodash/cloneDeep";
import { Awning, logic } from "@ldvsg/shared";
import options from "@/data/options";
import store from "@/store";
import * as profileConfig from "@profile/config";

export default Vue.extend({
  name: "Awning",
  components: {
    Visualization,
    AwningOptions,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      addAwningButton: false,
      activeTab: 0,
      awning: cloneDeep(this.$store.state.project.awning) as Awning,
      customer: cloneDeep(this.$store.state.project.customer),
      profileConfig,
    };
  },
  computed: {
    awningDepth() {
      return this.$store.getters.awningDepth;
    },
    awnings(): Awning["roofAwnings"] {
      return this.activeTab == 0
        ? this.awning.roofAwnings
        : this.awning.frontAwnings;
    },
    getTheRigthRemoteControl(): string {
      //Vielleicht als Feld ins Schema tun wie der WSGuard? Dann könnte man sich die Arbeit in der Bom sparen
      let awningCount: number =
        this.awning.roofAwnings.awnings.length +
        this.awning.frontAwnings.awnings.length +
        (this.awning.awningLeft.awning ? 1 : 0) +
        (this.awning.awningRight.awning ? 1 : 0);
      if (this.awning.controls === "motor")
        return "Keine Funksteuerung ausgewählt";
      if (this.awning.wsGuard) {
        if (awningCount > 1) return "5-Kanal Fernbedienung Wind/Sonnenwächter";
        else return "1-Kanal Fernbedienung Wind/Sonnenwächter";
      } else {
        if (awningCount > 1) return "5-Kanal Fernbedienung";
        else return "1-Kanal Fernbedienung";
      }
    },
    //TODO erst die Daten oben auswählen bevor eine Markise möglich ist muss noch validiert werden
    //TODONE glaub ich :P
    allowedFabricWidth(): number {
      if (profileConfig.config.defaultAwningWidth) return 6000;
      switch (this.awnings.fabricBrand) {
        case "lewens":
          switch (this.awning.variant) {
            case "on_roof":
              if (this.awningDepth! > 4000) {
                return 5000;
              } else if (this.awningDepth! > 3500) {
                return 5500;
              } else {
                return 6000;
              }
            case "under_glass":
              return 6000;
          }
        default:
          switch (this.awning.variant) {
            case "on_roof":
              return 5000;
            case "under_glass":
              return 6000;
            default:
              return 0;
          }
      }
    },
    awningMaxWidth(): number {
      const { width } = store.state.project!.configuration;
      const chevWidth = width! / (store.getters.chevrons - 1);
      return Math.floor(this.allowedFabricWidth / Math.floor(chevWidth));
    },
    awningFields(): number {
      return this.activeTab == 0
        ? store.getters.chevrons - 1
        : store.state.project!.configuration.postCount! - 1;
    },
    variantOptions() {
      return options.awningVariantOptions;
    },
    booleanOptions() {
      return options.boolOptions;
    },
    sideAwningVariantOptions() {
      return options.sideAwningVariantOptions;
    },
    fabricBrandOptions() {
      let res = options.fabricBrandOptions;
      if (res.some(it => it.value === 'nova')) {
        if (this.activeTab == 0 && this.awning.variant === 'under_glass') {
          res.find(it => it.value === 'nova')!.disabled = false
          res.find(it => it.value === 'nova')!.text = 'Nova Hüppe 200'
          res.find(it => it.value === 'nova_zip')!.text = 'Nova Hüppe 250 Zip'
        }
        else {
          res.find(it => it.value === 'nova')!.disabled = true
          res.find(it => it.value === 'nova_zip')!.text = 'Nova Hüppe 350 Zip'
        }
      }

      return [...res]
    },
    bauhausFabricOpt() {
      return options.fabricOptionsBauhaus;
    },
    fabricRegex(): RegExp {
      switch (this.awnings.fabricBrand) {
        case "lewens":
          return /^[0-9]+$/;
        case "erhardt":
          return this.activeTab == 0 ? /^T[P|A][0-9]+$/ : /^T[0-9]+$/;
        case "nova":
        case "nova_zip":
          return this.activeTab == 0 ? /^\d{6,}$/ : /^297\d{3,}$/
        default:
          return /^\w*$/;
      }
    },
    visibleTabs(): Array<string> {
      return profileConfig.options.awningPositionOptions;
    },
    spacingProblem(): boolean {
      let currentWall = this.$store.state.project.configuration.heightWall + 150
      let maxPossibleWall = this.$store.state.project.configuration.mountingHeightWall
      return logic.awningSpacing(currentWall, maxPossibleWall)
    }
  },
  methods: {
    postOffset(idx: number) {
      const arr = store.state.project?.configuration.postOffsets ?? [0, 0];
      if (idx == 0) return arr[0] != 0;
      else if (idx == 1) return arr[arr.length - 1] != 0;
    },
    changeAwningEndIfVariantChanges() {
      this.awnings.awnings.forEach((a) => {
        if (a.end! - a.start! > this.awningMaxWidth) {
          this.changeAwningEnd(this.awnings.awnings.indexOf(a), a.end! - 1);
        }
      });
    },
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        customer: cloneDeep(this.customer),
        awning: cloneDeep(this.awning),
        configuration: {
          ...this.$store.state.project.configuration,
          ...(this.awning.mountingPostPosition == "on_posts"
            ? { postVariant: "eckig" }
            : null),
        },
      });
      this.$store.commit("setUnsavedChanges", false);
    },
    addAwning() {
      const start =
        this.awnings.awnings[this.awnings.awnings.length - 1]?.end || 0;
      this.awnings.awnings.push({
        start,
        end:
          this.activeTab == 0
            ? Math.min(this.awningFields, start + this.awningMaxWidth)
            : start + 1,
        engineSide: null,
      });
    },
    removeAwning(index: number) {
      this.awnings.awnings.splice(index, 1);
    },
    changeAwningStart(index: number, start: number, ignoreMaxWidth = false) {
      if (start + 1 >= this.awnings.awnings[index].end!) {
        this.changeAwningEnd(index, start + 1, true);
      } else if (
        !ignoreMaxWidth &&
        start - 1 < this.awnings.awnings[index].end! - this.awningMaxWidth
      ) {
        this.changeAwningStart(index, start, true);
        this.changeAwningEnd(index, start + this.awningMaxWidth);
        return;
      }
      if (!ignoreMaxWidth) {
        start = Math.max(
          start,
          this.awnings.awnings[index].end! - this.awningMaxWidth
        );
        this.awnings.awnings[index].start = Math.max(
          Math.min(start, this.awnings.awnings[index].end! - 1),
          this.awnings.awnings[index - 1]?.end ?? 0
        );
        if (this.activeTab == 1) {
          this.awnings.awnings[index].end =
            this.awnings.awnings[index].start! + 1;
        }
      }
    },
    changeAwningEnd(index: number, end: number, ignoreMaxWidth = false) {
      if (!ignoreMaxWidth)
        end = Math.min(
          end,
          this.awnings.awnings[index].start! + this.awningMaxWidth
        );
      this.awnings.awnings[index].end = Math.min(
        Math.max(end, this.awnings.awnings[index].start! + 1),
        this.awnings.awnings[index + 1]?.start ?? this.awningFields
      );
    },
    addSideAwning(index: number) {
      if (index == 0) {
        this.awning.awningLeft = {
          sideAwningVariant: null,
          awning: {
            engineSide: null,
          },
          fabricID: null,
          fabricBrand: null,
          mountingPostPosition: null,
        };
      } else {
        this.awning.awningRight = {
          sideAwningVariant: null,
          awning: {
            engineSide: null,
          },
          fabricID: null,
          fabricBrand: null,
          mountingPostPosition: null,
        };
      }
    },
    removeSideAwning(index: number) {
      if (index == 0) {
        this.awning.awningLeft.sideAwningVariant = null;
        this.awning.awningLeft.awning = null;
        this.awning.awningLeft.fabricBrand = null;
        this.awning.awningLeft.fabricID = null;
        this.awning.awningLeft.mountingPostPosition = null;
      } else {
        this.awning.awningRight.sideAwningVariant = null;
        this.awning.awningRight.awning = null;
        this.awning.awningRight.fabricBrand = null;
        this.awning.awningRight.fabricID = null;
        this.awning.awningRight.mountingPostPosition = null;
      }
    },
    async downloadFabric() {
      const pdf: Blob = await this.$store.dispatch("getPreview", "fabric");
      const a = document.createElement("a");
      a.href = URL.createObjectURL(pdf);
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },

  created() {
    this.$watch(
      () => this.awning.awningLeft,
      () => {
        this.awning.awningRight.fabricBrand = this.awning.awningLeft.fabricBrand
        this.awning.awningRight.fabricID = this.awning.awningLeft.fabricID
      },
      { deep: true }
    )
    this.$watch(
      () => this.awning,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
    this.$watch(
      () => {
        this.awning.variant;
        this.awning.fabricBrand;
        this.awnings.fabricID;
      },
      () => {
        if (
          (this.activeTab == 0
            ? this.awning.roofAwnings.awnings.length
            : this.awning.frontAwnings.awnings.length) < 1 &&
          !this.addAwningButton
        )
          this.addAwning();
      },
      { deep: true }
    );
    this.$watch(
      () => {
        if (
          this.awningDepth > profileConfig.config.awningDepth &&
          this.activeTab == 0
        )
          this.addAwningButton = true;
        switch (this.awning.awningLeft.sideAwningVariant) {
          case "pos1":
            this.awning.awningLeft.mountingPostPosition = "on_posts";
            break;
          case "pos2":
            this.awning.awningLeft.mountingPostPosition = "on_posts";
            break;
          case "pos3":
            this.awning.awningLeft.mountingPostPosition = "between_posts";
            break;
          case "pos4":
            this.awning.awningLeft.mountingPostPosition = "between_posts";
            break;
          case "pos5":
            this.awning.awningLeft.mountingPostPosition = "on_posts";
            break;
        }
        switch (this.awning.awningRight.sideAwningVariant) {
          case "pos1":
            this.awning.awningRight.mountingPostPosition = "on_posts";
            break;
          case "pos2":
            this.awning.awningRight.mountingPostPosition = "on_posts";
            break;
          case "pos3":
            this.awning.awningRight.mountingPostPosition = "between_posts";
            break;
          case "pos4":
            this.awning.awningRight.mountingPostPosition = "between_posts";
            break;
          case "pos5":
            this.awning.awningRight.mountingPostPosition = "on_posts";
            break;
        }
      },
      () => {}
    );
  },
  async beforeRouteLeave(to, _from, next) {
    if (
      !["Projects", "CustomerInformation", "GeneralData"].includes(
        to.name ?? ""
      ) &&
      ((this.activeTab == 0 &&
        this.awning.roofAwnings.awnings.length > 0 &&
        !(await (this.$refs.formroof as any)[0].validate())) ||
        (this.activeTab == 1 &&
          this.awning.frontAwnings.awnings.length > 0 &&
          !(await (this.$refs.formfront as any)[0].validate())) ||
        (this.activeTab == 2 &&
          !(await (this.$refs.formside as any).validate())) ||
        ((this.awning.roofAwnings.awnings.length > 0 ||
          this.awning.frontAwnings.awnings.length > 0) &&
          !(await (this.$refs.form as any).validate())))
    )
      return;
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },
});
