import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import { keycloak } from "@/auth"
import store from "@/store"

import Projects from "@/views/Projects.vue"
import Project from "@/views/Project.vue"
import EditArticles from "@/views/EditArticles.vue"
import EditPromoCodes from "@/views/EditPromoCodes.vue"

import CustomerInformation from "@/views/Project/CustomerInformation.vue"
import GeneralData from "@/views/Project/GeneralData.vue"
import SidePanels from "@/views/Project/SidePanels.vue"
import Lighting from "@/views/Project/Lighting.vue"
import Overview from "@/views/Project/Overview.vue"
import Awning from "@/views/Project/Awning.vue"
import Mounting from "@/views/Project/Mounting.vue"
import Payment from "@/views/Project/Payment.vue"
import Summary from "@/views/Project/Summary.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "Projects" }
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/projects/:project",
    name: "Project",
    component: Project,
    redirect: { name: "CustomerInformation" },
    children: [
      {
        path: "customer-information",
        name: "CustomerInformation",
        component: CustomerInformation
      },
      {
        path: "general-data",
        name: "GeneralData",
        component: GeneralData
      },
      {
        path: "overview",
        name: "Overview",
        component: Overview
      },
      {
        path: "lighting",
        name: "Lighting",
        component: Lighting
      },
      {
        path: "awning",
        name: "Awning",
        component: Awning
      },
      {
        path: "side-panels",
        name: "SidePanels",
        component: SidePanels
      },
      {
        path: "mounting",
        name: "Mounting",
        component: Mounting
      },
      {
        path: "payment",
        name: "Payment",
        component: Payment
      },
      {
        path: "summary",
        name: "Summary",
        component: Summary
      }
    ]
  },
  {
    path: "/edit-articles",
    component: EditArticles,
    beforeEnter: async (to, from, next) => {
      const user = store.state.authUser
      if(!user?.roles["Konfigurator"]?.includes("manager")) {
        next("/")
      }
      else next()
    }
  },
  {
    path: "/edit-promo",
    component: EditPromoCodes,
    beforeEnter: async (to, from, next) => {
      const user = store.state.authUser
      if(!user?.roles["Konfigurator"]?.includes("manager")) next("/")
      else next()
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach(async (to, from, next) => {
  if (!keycloak.authenticated) {
    console.log("not authenticated!")
    await keycloak.login()
  }

  if (store.state.unsavedChanges) {
    await Vue.nextTick()
    if (!confirm("Unsaved changes")) return next(false)
  }

  if (to.params.project) {
    await store.dispatch("loadProject", +to.params.project)
  }

  next()
})

addEventListener("beforeunload", event => {
  if (store.state.unsavedChanges) event.returnValue = true
})
