import Vue from "vue"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import store from "./store"
import router from "./router"
import "./styles.scss"
import "./directives/click-outside"
import "./directives/disable-all"
import App from "./App.vue"
import "./vee-validate.js"
import { keycloak, AuthPlugin } from "./auth"
import "@profile/info"

Vue.config.productionTip = false

if (process.env.NODE_ENV == "development") {
  Object.assign(window, { store, router })
}

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(AuthPlugin)

; (async () => {
  try {
    const authenticated = await keycloak.init()
    if (!authenticated) await keycloak.login()
    store.commit("setAuthUser", keycloak.userInfo)

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app")

  } catch(e) {
    console.log(e)
  }
})()
