







import Vue, { PropType } from "vue"
import store from "@/store"
import { Awning, Configuration } from "@ldvsg/shared"
import { Visualization, VisualizationInput } from "./Visualization"

export default Vue.extend({
  name: "Visualization",
  props: {
    awning: { type: Object as PropType<Awning>, required: true },
    withMeasurements: { type: Boolean, required: false, default: false},
    withLighting: { type: Boolean, required: false, default: false}
  },
  data() {
    return {} as { visualization: Visualization }
  },
  mounted() {
    const canvas = this.$refs.mainCanvas as HTMLCanvasElement
    const overlayCanvas = this.$refs.overlayCanvas as HTMLCanvasElement
    overlayCanvas.width = 900
    overlayCanvas.height = 450

    this.visualization = new Visualization(canvas, overlayCanvas)

    this.$watch(() => {
      const { configuration: config } = store.state.project!
      return {
        showMeasurements: this.withMeasurements,
        showLighting: this.withLighting,
        width: config.width! / 1000,
        depth: config.depth! / 1000,
        heightWall: config.heightWall! / 1000,
        heightGutter: config.heightGutter! / 1000,
        postCount: config.postCount,
        chevronCount: store.getters.chevrons!,
        awning: this.awning,
        postOffsets: config.postOffsets.map((element: number) => element / 1000),
        unterzugPostCount: (() => {
          let count: number
          switch(config.unterzugType) {
            case 1:
            case 2:
            case 4:
              count = 2
              break
            case 3:
              count = 3
              break
            default:
              count = 0
              break
          }
          return count
        })(),
        colorCode: config.colorCode,
      } as VisualizationInput
    }, input => this.visualization.update(input), {
      immediate: true,
      deep: true
    })

    addEventListener("resize", this.visualization.updateSize)
    this.visualization.updateSize()
  },
  destroyed() {
    removeEventListener("resize", this.visualization.updateSize)
    this.visualization.dispose()
  }
})
