
export function post(a: number, roofing: string) {
  if (roofing == null) return 0;
  return Math.ceil(a / (["poly_klar", "poly_opal", "poly_ir", "powerplastics"].includes(roofing) ? 5000 : 4000)) + 1
}

export function chevron(roofWidth: number, isGlassRoofing: boolean) {
  const maxWidth = isGlassRoofing ? 750 : 1000
  return Math.ceil(roofWidth / maxWidth) + 1
}

export function gradient(depth: number, wallheight: number, postheight: number) {
  // if (depth == null || wallheight == null || postheight == null) return null
  const a = wallheight - postheight;
  return Math.atan(a / depth) * 180 / Math.PI
}

export function roofArea(width: number, depth: number, triangleHeight: number) {
  return width * roofLength(depth, triangleHeight)
}

export function consoleAmount(width: number) {
  return Math.ceil(width / 800)
}

export function awningChevrons(width: number, glass: boolean, awningVariant: string) {
  const chevWidth = width / (chevron(width, glass)-1)
  const variant = awningVariant == "on_roof" ? 5000 : 6000
  return Math.floor(variant / chevWidth)
}

export function roofLength(depth: number, triangleHeight: number) {
  return Math.sqrt(depth ** 2 + triangleHeight ** 2)
}


export function triangleHeight(heightGutter: number, heightWall: number) {
  return heightWall - heightGutter
}
