


















import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  props: {
    value: {},
    rules: {},
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
    },
  },
  components: { ValidationProvider },
});
