

















































































































































import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedFormField from "@/components/ValidatedFormField.vue";
import cloneDeep from "lodash/cloneDeep";
import opt from "@/data/options";
import { logic } from "@ldvsg/shared";
import store from "@/store";

export default Vue.extend({
  data() {
    return {
      sidePanels: cloneDeep(this.$store.state.project.sidePanels),
      customer: cloneDeep(this.$store.state.project.customer),
      activeTab: 0,
    };
  },
  components: { ValidationObserver, ValidatedFormField, ValidationProvider },
  computed: {
    width: () => store.state.project?.configuration.width,
    depth: () => store.state.project?.configuration.depth,
    heightGutter: () => store.state.project?.configuration?.heightGutter,
    totalPosts: () => store.state.project?.configuration?.postCount,
    triangleHeight: () => store.getters.triangleHeight,
    postOffsets: () => store.getters.postOffsets,
    options: () => opt.boolOptions,
    sideOptions(): any[] {
      return opt.panelOpeningOptions.filter(it => {
        return it.available.includes(this.activeTab) || it.available[0] == 2 && this.activeTab >= 2
      })
    },
    swingDoorOptions: () => opt.swingDoorOpeningOptions,
    panelOpt: () => opt.panelOpt,
    awnings: () => [
      store.state.project?.awning.awningLeft.awning,
      store.state.project?.awning.awningRight.awning
    ],
    leftSideAwningVariant: () => store.state.project?.awning.awningLeft.sideAwningVariant,
    rightSideAwningVariant: () => store.state.project?.awning.awningRight.sideAwningVariant,

    variants(): any[] {
      return opt.sidePanelVariants.filter(it => this.activeTab > 1 && !it.value?.includes("keil") || this.activeTab <= 1 && !(["gga", "festelement"].includes(it.value!)))
    }
  },
  methods: {
    disable(name: string[]) {
      name.forEach(entry => {
        this.variants.find((opt) => opt.value == entry).disabled = true;
      })
    },
    enable(name: string[]) {
      name.forEach(entry => {
        this.variants.find((opt) => opt.value == entry).disabled = false;
      })
    },
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        customer: cloneDeep(this.customer),
        sidePanels: cloneDeep(this.sidePanels)
      })
      this.$store.commit("setUnsavedChanges", false)
    },
    sidePossibleOffset(idx: number): boolean {
      if (idx == 0 && this.postOffsets[0] != 0) return false
      if (idx == 1 && this.postOffsets[this.postOffsets.length - 1] != 0) return false
      return true
    },
    sidePossiblePostMounting(idx: number): boolean {
      switch (this.activeTab) {
        case 0:
          return store.state.project?.awning.awningLeft.mountingPostPosition == "between_posts"
            ? this.awnings[0] == null
            : true
        case 1:
          return store.state.project?.awning.awningRight.mountingPostPosition == "between_posts"
            ? this.awnings[1] == null
            : true
        default:
          return store.state.project?.awning.mountingPostPosition == "between_posts"
            ? this.awnings[idx] == null
            : true
      }
    },
  },
  created() {
    this.$watch(
      () => this.sidePanels,
      () => {
        this.$store.commit("setUnsavedChanges", true);
      },
      { deep: true }
    );
    //Beim ersten öffnen des Projekts das Objekt anlegen
    if (this.sidePanels.sidePanels === null) {
      this.sidePanels.sidePanels = [
        {
          side: "Links",
          panelConfiguration: [],
          variant: null,
          width: this.depth,
          accessories: null,
          accessoryPrice: null,
          opening: null,
        },
        {
          side: "Rechts",
          panelConfiguration: [],
          variant: null,
          width: this.depth,
          accessories: null,
          accessoryPrice: null,
          opening: null,
        },
      ];
      // Die Seitenelemente mit der Unterteilung fertig machen
      for (let i = 0; i < logic.panelDivisions(this.depth).amount; i++) {
        this.sidePanels.sidePanels[0].panelConfiguration.push({
          panelOpt: null,
          panelWidth: logic.panelDivisions(this.depth).width,
          plankHeight: null,
          doorOpening: null
        })
        this.sidePanels.sidePanels[1].panelConfiguration.push({
          panelOpt: null,
          panelWidth: logic.panelDivisions(this.depth).width,
          plankHeight: null,
          doorOpening: null
        })
      }
      const sides = logic.sides(this.totalPosts!);
      for (var i = 0; i < sides; i++) {
        let w = logic.offsetPanelWidth(
          i,
          this.postOffsets,
          this.width!,
          this.totalPosts!
        )
        let panelConfiguration = []
        for (let i = 0; i < logic.panelDivisions(w).amount; i++) {
          panelConfiguration.push({
            panelOpt: null,
            panelWidth: logic.panelDivisions(w).width,
            plankHeight: null,
            doorOpening: null
          })
        }
        // Das Array mit den "fertigen" Seitenelementen befüllen
        this.sidePanels.sidePanels.push({
          side: "Vorne " + (i + 1),
          panelConfiguration: panelConfiguration,
          variant: null,
          width: w,
          accessories: null,
          accessoryPrice: null,
          opening: null,
        });
        
      }
    }
    const sides = logic.sides(this.totalPosts!);

    // Bei Anpassungen im Projekt die Anzahl der Elemente Anpassen
    while (this.sidePanels.sidePanels.length - 2 > sides) {
      this.sidePanels.sidePanels.pop();
    }
    while (this.sidePanels.sidePanels.length - 2 < sides) {
      this.sidePanels.sidePanels.push({
        side: "Vorne " + (this.sidePanels.sidePanels.length - 1),
        panelConfiguration: [],
        variant: null,
        width: logic.panelWidth(this.width!, this.totalPosts!),
        accessories: null,
        accessoryPrice: null,
        opening: null,
      });
    }
    //Nach dem Anpassen der Element-Anzahl die Breiten neu bestimmen
    for (var i = 0; i < sides; i++) {
      let w = logic.offsetPanelWidth(
        i,
        this.postOffsets,
        this.width!,
        this.totalPosts!
      ); 
      let panelConfiguration = []
      for (let i = 0; i < logic.panelDivisions(w).amount; i++) {
        panelConfiguration.push({
          panelOpt: null,
          panelWidth: logic.panelDivisions(w).width,
          plankHeight: null,
          doorOpening: null
        })
      }
      this.sidePanels.sidePanels[i + 2].width = w
      this.sidePanels.sidePanels[i + 2].panelConfiguration = panelConfiguration
    }
    
    this.$watch(
      () => {
        if (this.leftSideAwningVariant && this.activeTab == 0) {
          switch (this.leftSideAwningVariant) {
            case "pos1":
              this.disable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos2":
              this.disable([
                "gga_keil",
                "festelement_keil",
              ]);
              this.enable(["keil"]);
              this.sidePanels.sidePanels[0].variant = "keil";
              break;
            case "pos3":
              this.disable([
                "gga_keil",
              ]);
              this.enable(["festelement_keil", "keil"]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos4":
              this.disable([
                "gga_keil",
              ]);
              this.enable(["festelement_keil", "keil"]);
              this.sidePanels.sidePanels[0].variant = null;
              break;
            case "pos5":
              this.enable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              break;
            case "pos6":
              this.enable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              break;
            case "pos7":
              this.disable([
                "gga_keil",
                "festelement_keil",
              ]);
              this.enable([
                "keil",
              ]);
              break;
          }
        } else if (this.activeTab == 0)
          this.enable([
            "gga_keil",
            "festelement_keil",
            "keil",
          ]);

        if (this.rightSideAwningVariant && this.activeTab == 1) {
          switch (this.rightSideAwningVariant) {
            case "pos1":
              this.disable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos2":
              this.disable([
                "gga_keil",
                "festelement_keil",
              ]);
              this.sidePanels.sidePanels[1].variant = "keil";
              break;
            case "pos3":
              this.disable([
                "gga_keil",
              ]);
              this.enable(["festelement_keil", "keil"]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos4":
              this.disable([
                "gga_keil",
              ]);
              this.enable(["festelement_keil", "keil"]);
              this.sidePanels.sidePanels[1].variant = null;
              break;
            case "pos5":
              this.enable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              break;
            case "pos6":
              this.enable([
                "gga_keil",
                "festelement_keil",
                "keil",
              ]);
              break;
            case "pos7":
              this.disable([
                "gga_keil",
                "festelement_keil",
              ]);
              this.enable([
                "keil",
              ]);
              break;
          }
        } else if (this.activeTab == 1)
          this.enable([
            "gga_keil",
            "festelement_keil",
            "keil",
          ]);
        if (this.activeTab > 1)
          this.enable([
            "gga",
            "festelement",
          ]);
      },
      () => {}
    );
  },
  async beforeRouteLeave(to, _from, next) {
    if (
      this.$refs.form &&
      ![
        "Projects",
        "CustomerInformation",
        "GeneralData",
        "Awning",
        "Lighting",
      ].includes(to.name ?? "") &&
      !(await (this.$refs.form as any).validate())
    )
      return;
    try {
      await this.save();
    } catch (e) {
      console.log(e);
    }
    next();
  },
});
