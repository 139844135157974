/* eslint-disable */
import { required,  email, max_value, min_value, digits, excluded, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Pflichtfeld"
});
extend("email", {
  ...email,
  message: "Bitte geben sie eine gültige Email ein"
});
extend("maxValue", {
  ...max_value,
  message: "Wert zu hoch"
});
extend("minValue", {
  ...min_value,
  message: "Wert zu niedrig"
});
extend("zip", {
  ...digits,
  message: "Bitte geben sie eine gültige PLZ an"
});

extend("noCustomColor", {
  ...excluded,
  message: "Dies ist eine Standardfarbe. Bitte wählen Sie einen anderen Farbton."
});

extend("regex", {
  ...regex,
  message: "Ungültiges Format"
})