import { options as config, options } from "@profile/config"

export default {
  referredOptions: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "ab_zum_angebot", text: "Ab zum Angebot" },
    { value: "aktion", text: "Aktion" },
    { value: "amazon", text: "Amazon" },
    { value: "ausstellung", text: "Ausstellung" },
    { value: "daa_net", text: "DAA Net" },
    { value: "email", text: "E-Mail" },
    { value: "ebay", text: "Ebay" },
    { value: "ebay_kleinanzeigen", text: "Ebay-Kleinanzeigen" },
    { value: "empfehlung", text: "Empfehlung" },
    { value: "facebook", text: "Facebook" },
    { value: "flyer", text: "Flyer" },
    { value: "konfigurator", text: "Konfigurator" },
    { value: "konfigurator_kampange", text: "Konfigurator Kampange" },
    { value: "messe", text: "Messe" },
    { value: "newsletter", text: "Newsletter" },
    { value: "telefon", text: "Telefon" },
    { value: "obi", text: "OBI" },
  ],
  awningVariantOptions: [
    { value: null, text: "Bitte wählen", disabled: false },
    { value: "on_roof", text: "Aufdach" },
    { value: "under_glass", text: "Unterglas" }
  ],
  sideAwningVariantOptions:[
    { value: null, text: "Bitte wählen" ,disabled: false },
    // { value: "pos1", text: "Seitl. Markise auf den Pfosten mit Profilrahmen – keine Elemente gewünscht  (Pos. 1)" },
    // { value: "pos2", text: "Seitl. Markise auf den Pfosten mit Keil (Pos. 2)" },
    // { value: "pos3", text: "Seitl. Markise zwischen den Pfosten mit Profilrahmen – keine Elemente gewünscht (Pos. 3)" },
    // { value: "pos4", text: "Seitl. Markisen zwischen den Pfosten mit Keil (Pos. 4)" },
    // { value: "pos5", text: "Seitl. Markise auf den Pfosten mit Elementen – normale Konfiguration in Elementen vornehmen ( Pos. 5)" }
    { value: "pos6", text: "Seitl. Markise auf den Pfosten" },
    { value: "pos7", text: "Seitl. Markise zwischen den Pfosten (keine GGS/Festelemente möglich)" },
  ],
  awningControls: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "motor", text: "Standard-Steuerung (Schalter Bauseits)" },
    { value: "motor_funk", text: "Funkgesteuert (Vorgerüstet für Smart Home)" }
  ],
  mountingPostOptions: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "on_posts", text: "Auf den Pfosten" },
    { value: "between_posts", text: "Zwischen den Pfosten" }
  ],
  fabricBrandOptions: [
    { value: null, text: "Bitte wählen", disabled: true },
    ...config.awningFabricOptions,
  ],
  awningEngineSide:[
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "left", text: "Links" },
    { value: "right", text: "Rechts" }
  ],
  lightingVariantOptions: [
    { value: null, text: "Bitte wählen", disabled: true },
    ...config.additionalLightingOptions,
    { value: "led_spots", text: "Dimmbare LED Spots" },
  ],
  lightingVariantOptionsAdditional: [
    { value: null, text: "Bitte wählen" },
    { value: "led_spots", text: "Dimmbare LED Spots" }
  ],
  sidePanelVariants: [
    { value: null, text: "Bitte Wählen", disabled: false },
    { value: "gga", text: "Ganzglas Schiebeanlage" },
    { value: "gga_keil", text: "Ganzglas Schiebeanlage mit Keil" },
    { value: "festelement", text: "Festelement" },
    { value: "festelement_keil", text: "Festelement mit Keil" },
    { value: "keil", text: "Keil" }
  ],
  panelOpt: [
    { value: null, text: "Bitte wählen" },
    { value: "aluplanken", text: "Aluplanken" },
    { value: "einfluegelig", text: "Nebeneingangstür einflügelig" },
    { value: "doppelfluegelig", text: "Nebeneingangstür doppelflügelig" },
    { value: "fenster_bruestung", text: "Dreh-/Kippfenster Brüstung" },
    { value: "fenster_bodentief", text: "Dreh-/Kippfenster Bodentief" },
    { value: "verglasung_bruestung", text: "Verglasung Brüstung inkl Handlauf 1m hoch" },
    { value: "verglasung", text: "Verglasung Bodentief" }
  ],
  panelOpeningOptions: [
    { value: "left", text: "nach Links", available: [2, 3, 4, 5, 6] },
    { value: "right", text: "nach Rechts", available: [2, 3, 4, 5, 6] },
    { value: "toWall", text: "vom Pfosten zur Hauswand", available: [0, 1] },
    { value: "toPost", text: "von der Hauswand zum Pfosten", available: [0, 1] },
  ],
  swingDoorOpeningOptions: [
    { value: "dinL", text: "DIN L" },
    { value: "dinR", text: "DIN R" }
  ],
  boolOptions: [
    { value: true, text: "Ja" },
    { value: false, text: "Nein" }
  ],
  color: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: 9016, text: "Weiß 9016 Glatt" },
    { value: 7016, text: "Anthrazit 7016 Strukur" },
    { value: 9007, text: "Graualuminium 9007 Struktur" },
    ...config.additionalColorOptions
  ],
  roofing: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "vsg_klar", text: "VSG 10mm klar" },
    ...config.additionalRoofingOptions,
    { value: "poly_klar", text: "Poly klar" },
    { value: "poly_opal", text: "Poly Opal" },
    { value: "poly_ir", text: "Poly IR" }
  ],
  postVariant: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "eckig", text: "Eckig" },
    { value: "abgerundet", text: "Abgerundet" }
  ],
  wallOpt: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "massiv", text: "Massiv" },
    { value: "holzstaenderbauweise", text: "Holzständerbauweise" }
  ],
  mountingOpt: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "wand", text:"Wand", disabled: false },
    { value: "aufdach", text: "Aufdach", disabled: false },
    { value: "hausdachsparren", text: "Hausdachsparren" },
    { value: "freistehend", text: "Freistehend" },
    { value: "ueberbrueckung", text: "Wand massiv mit Überbrückung" }
  ],
  measuringOpt: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "links", text: "Hausecke Links" },
    { value: "rechts", text: "Hausecke Rechts" },
    { value: "laibung_links", text: "Fensterlaibung Links" },
    { value: "laibung_rechts", text: "Fensterlaibung Rechts" }
  ],
  postsOpt: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "fest", text: "Fester Grund" },
    { value: "lose", text: "Loser Grund (Fundament benötigt)" }
  ],
  paymentOptions: [
    ...config.paymentOptions
  ],
  salesmanList: [
    { value: null, text: "Bitte wählen", disabled: true },
    { value: "v0001", text: "V0001 | Martin Szepat" },
    { value: "v0033", text: "V0033 | Marc Olbrück" },
    { value: "v0038", text: "V0038 | Kevin Einax" },
    { value: "v0037", text: "V0037 | Ewald Lang" },
    { value: "v0002", text: "V0002 | Michael Lagrave" },
    { value: "v0003", text: "V0003 | Eugen Batt" },
    { value: "v0004", text: "V0004 | Bernhard Brantsch" },
    { value: "v0005", text: "V0005 | Maik Heidrich" },
    { value: "v0006", text: "V0006 | Manfred Brehm" },
  ],
  accessibilityOptions:[
    { value: null, text: "Bitte wählen"},
    { value: "am", text: "Vormittags 9.00-13.00 Uhr"},
    { value: "pm", text: "Nachmittags 13.00-18.00 Uhr"}
  ],
  fabricOptionsBauhaus: [
    { value: null, text: "Bitte wählen", disabled: true},
    { value: "u137", text: "U137"},
    { value: "u140", text: "U140"},
    { value: "u335", text: "U335"},
    { value: "u406", text: "U406"},
    { value: "u409", text: "U409"},
    { value: "u411", text: "U411"},
    { value: "u415", text: "U415"},
    { value: "0867", text: "0867"},
    { value: "6688", text: "6688"},
    { value: "8396", text: "8396"}
  ],
  deliveryOptionsBauhaus: [
    { value: null, text: "Bitte wählen", disabled: true},
    ...config.deliveryOptions
  ],
  marketOptions: [
    { value: "bauhaus", text: "Bauhaus" },
    { value: "baywa", text: "Baywa" },
    { value: "hellweg", text: "Hellweg" },
    { value: "ldvsg", text: "LDVSG" }
  ],
  statusOptions: [
    ...config.statusOptions
  ],
  unterzugOptions: [
    { value: 1, text: "Alu-Unterzug" },
    { value: 2, text: "Alu-Unterzug mit Stahleinlage" },
    { value: 3, text: "Alu-Unterzug auf drei Pfosten" },
    { value: 4, text: "Stahlunterzug" }
  ]
}
