





























































































































































































































































import { validate, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import ValidatedFormField from "@/components/ValidatedFormField.vue"
import { consoleAmount } from "@/logic/logic"
import cloneDeep from "lodash/cloneDeep"
import opt from "@/data/options"
import store from '@/store'

export default Vue.extend({
  components: { ValidationObserver, ValidationProvider, ValidatedFormField },
  data() {
    return {
      mounting: cloneDeep(this.$store.state.project.mounting),
      configuration: cloneDeep(this.$store.state.project.configuration),
      mountingOpt: [] as Array<any>,
      foundationOptions: [ "Vorne", "Hinten" ]
    }
  },
  computed: {
    width: () => store.state.project?.configuration.width,
    totalPosts: () => store.state.project?.configuration.postCount,
    heightWall: () => store.state.project?.configuration.heightWall,
    roofing: () => store.state.project?.configuration.roofingVariant,
    wallOpt: function () {
      return opt.wallOpt
    },
    booleanOptions() {
      return opt.boolOptions;
    },
    measuringOpt: function () {
      return opt.measuringOpt
    },
    postsOpt: function () {
      return opt.postsOpt
    },
    postArray(): Array<any> {
      if (!['freistehend', 'ueberbrueckung'].includes(this.mounting.mountingVariant)) return [this.mounting.postMountingFront]
      else return [this.mounting.postMountingFront, this.mounting.postMountingRear]
    },
    middlePosts(): Array<any> {
      return this.mounting.postMountingMiddle
    },
    foundationDisabled(): number {
      if (this.mounting.foundationSide === "Vorne") return 0
      else if (this.mounting.foundationSide === "Hinten") return 1
      else return 3
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("updateProject", {
        ...this.$store.state.project,
        mounting: cloneDeep(this.mounting),
        configuration: cloneDeep(this.configuration)
      })
      this.$store.commit("setUnsavedChanges", false)
    },
  },
  created() {
    // Kleine Fehlerbehebung für Bestehende Projekte: keine Hinteren Pfosten berechnen
    if (!['freistehend', 'ueberbrueckung'].includes(this.mounting.mountingVariant)) this.mounting.rearPosts = 0
    this.$watch(() => {
      if (!['freistehend', 'ueberbrueckung'].includes(this.mounting.mountingVariant)) this.mounting.rearPosts = 0
    }, 
    () => { })
    
    this.mountingOpt = [... opt.mountingOpt]
    this.$watch(() => {
      //mountingVariant -> freistehend
      if (this.mounting.wallThickness > 150) {
        this.mountingOpt.find(opt => opt.value === "wand")!.disabled = true
        this.mountingOpt.find(opt => opt.value === "ueberbrueckung")!.disabled = true
      } else {
        this.mountingOpt.find(opt => opt.value === "wand")!.disabled = false
        this.mountingOpt.find(opt => opt.value === "ueberbrueckung")!.disabled = false
      }
      //consoles
      this.mounting.consoleAmount = consoleAmount(this.width!)

      if (this.mounting.postMountingRear == null) this.mounting.postMountingRear = []
      while (this.mounting.rearPosts! > this.mounting.postMountingRear.length) this.mounting.postMountingRear.push({
        foundation: null,
        groundAdjustment: null,
        extraPostLength: null,
        postLength: 0
      })
      while (this.mounting.rearPosts! < this.mounting.postMountingRear.length) {
        this.mounting.postMountingRear.pop()
      }
      if (this.mounting.foundationSide === "Vorne") {
        this.mounting.rearPostsLength = this.heightWall
        this.mounting.postMountingFront.forEach((el: any) => {
          el.foundation = "lose"
          el.extraPostLength = true
        });
      } else if (this.mounting.foundationSide === "Hinten") {
        this.mounting.rearPostsLength = this.heightWall! + 800
        this.mounting.postMountingRear.forEach((el: any) => {
          el.foundation = "lose"
          el.extraPostLength = true
        });
      }
    }, () => { })
    this.$watch(() => this.mounting.wall, () => {
      if (this.mounting.wall == "holzstaenderbauweise") {
        this.mountingOpt.find(opt => opt.value === "wand")!.disabled = true
      } else {
        this.mountingOpt.find(opt => opt.value === "wand")!.disabled = false
      }
    })
    this.$watch(() => this.mounting, () => {
      this.$store.commit("setUnsavedChanges", true)
    }, { deep: true })

    if (["vsg_klar", "vsg_milch"].includes(this.roofing!)) {
      this.mountingOpt.find(
        (opt) => opt.value == "aufdach"
      )!.disabled = true
    } else {
      this.mountingOpt.find(
        (opt) => opt.value == "aufdach"
      )!.disabled = false
    }

    if (this.mounting.postMountingFront == null) this.mounting.postMountingFront = []
    while (this.totalPosts! > this.mounting.postMountingFront.length) this.mounting.postMountingFront.push({
      foundation: null,
      groundAdjustment: null,
      extraPostLength: null,
      postLength: 0
    })
    while (this.totalPosts! < this.mounting.postMountingFront.length) {
      this.mounting.postMountingFront.pop()
    }

    if (this.mounting.postMountingMiddle == null) this.mounting.postMountingMiddle = []
    while (([1, 2, 4].includes(this.configuration.unterzugType) ? 2 : 3) > this.mounting.postMountingMiddle.length) this.mounting.postMountingMiddle.push({
      foundation: null,
      groundAdjustment: null,
      extraPostLength: null,
      postLength: 0
    })
    while (([1, 2, 4].includes(this.configuration.unterzugType) ? 2 : 3) < this.mounting.postMountingMiddle.length) {
      this.mounting.postMountingMiddle.pop()
    }
  
  },
  async beforeRouteLeave(to, _from, next) {
    if (!["Projects", "CustomerInformation", "GeneralData", "Awning", "Lighting", "SidePanels"].includes(to.name ?? "") && !(await (this.$refs.form as any).validate()))
      return;
    try {
      await this.save()
    } catch (e) {
      console.log(e)
    }
    next()
  }

})
