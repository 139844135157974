









import Vue from 'vue'
import { Awning } from "@ldvsg/shared";
import cloneDeep from "lodash/cloneDeep"
import Visualization from "@/components/Visualization.vue"

export default Vue.extend({
  components: { Visualization },
  data() {
    return {
      awning: cloneDeep(this.$store.state.project.awning) as Awning,
    }
  }
})
