















import Vue from "vue";
import SidebarView from "@/components/Sidebar.vue";
import SupportModal from "@/components/SupportModal.vue";
import ButtonFooter from "@/components/ButtonFooter.vue";

export default Vue.extend({
  name: "Project",
  components: {
    SidebarView,
    SupportModal,
    ButtonFooter,
  },
});
