











import Vue from "vue"
import HeaderView from "@/components/Header.vue"

export default Vue.extend({
  name: "App",
  components: {
    HeaderView
  }
})
