











































































import Vue from "vue"
import PhoneSupport from "@/components/PhoneSupport.vue"
import { BvTableFieldArray } from "bootstrap-vue"
import { Configuration, defaultProject, Project, ProjectStatus } from "@ldvsg/shared"
import store from '@/store'
import { modules } from "@profile/config"
import options from "@/data/options"
import { debounce } from "lodash"

export default Vue.extend({
  components: { PhoneSupport },
  data() {
    return {
      modules,
      query: null
    }
  },
  name: "Projects",
  computed: {
    currentPage: {
      set(page: number) {
        store.commit("setProjectFilter", {
          ...store.state.projectFilter,
          currentPage: page
        })
        store.dispatch("loadProjects")
      },
      get() {
        return store.state.projectFilter.currentPage
      }
    },
    totalItems() {
      return store.state.totalProjects
    },
    itemsPerPage(): number {
      return store.state.projectFilter.itemsPerPage
    },
    loading(): boolean {
      return store.state.loadingProjects
    },
    projects(): Project[] {
      return store.state.projects
    },
    projectFields(): BvTableFieldArray {
      return [
        {
          key: "id",
          thStyle: { width: "0%" },
          label: "#",
          sortable: true
        },
        {
          key: "configuration",
          label: "Dachgröße",
          thStyle: { width: "70%" },
          formatter: (configuration: Configuration | null) =>
            configuration?.width && configuration.depth ?
              `${configuration?.width}mm x ${configuration?.depth}mm` :
              "Kein Dach konfiguriert"
        },
        {
          key: "createdAt",
          label: "Erstellt am",
          thStyle: { width: "15%" },
          formatter: (createdAt: Date) => this.formatDate(createdAt)
        },
        {
          key: "status",
          label: "Status",
          thStyle: { minWidth: "200px" },
          formatter: (value: ProjectStatus) =>
            this.statusList.find(status => status.id == value)?.title
        },
      ]
    },
    statusList(): { id: string, title: string, active: boolean, query: Record<string, string> }[] {
      return options.statusOptions.map(status => {
        const filter = new Set(this.filterStatus)

        const active = filter.has(status.id)
        if (active) filter.delete(status.id)
        else filter.add(status.id)

        const query: any = { ...this.$route.query }
        if (filter.size > 0) query.status = [...filter].join()
        else delete query.status

        return { ...status, active, query }
      }).filter(status => {
        return this.$store.getters.userRole("admin") || status.id !== "deleted"
      })
    },
    filterStatus(): string[] {
      return "status" in this.$route.query && this.$route.query.status != ""
        ? String(this.$route.query.status).split(",") : []
    },
    disableStatus: () => (status: string) => {
      if (store.getters.userRole('admin')) return false
      if (!modules.editStatus) return true
      if (["created", "planning", "orderPlaced", "denied", "sentToManufacturer", "planningByManufacturer"].includes(status)) return true
      return false
    },
  },
  methods: {
    isProjectCompatible(project: Project | null) {
      return project?.schemaVersion == defaultProject.schemaVersion
    },
    getTrClass(project: Project | null) {
      return !this.isProjectCompatible(project) && 'outdated'
    },
    async createProject() {
      const project = await store.dispatch('createProject')
      this.$router.push({ name: 'Project', params: { project: project.id } })
    },
    async setStatus(project: Project, status: string) {
      if (status === "planningComplete" && this.$store.state.project?.status === "onSiteAppointment") {
        if (project.customer.firstName === "") throw new Error('Customer must not be empty')
        await store.dispatch("updateProject", {
          ...project,
          status,
          customer: {
            ...project.customer,
            continueWithSalesman: false
          }
        })
      } else {
        await store.dispatch("updateProject", {
          ...project,
          status
        })
      }
      await store.dispatch("loadProjects")
    },
    formatDate(date: Date) {
      return date.toLocaleString("de", {
        day: "2-digit", month: "2-digit", year: "2-digit",
        hour: "2-digit", minute: "2-digit", hour12: false
      })
    },
    handleKeyUp(evt: any) {
      if (evt.keyCode === 13) {
        evt.preventDefault()
        this.$store.dispatch('loadProjects')
      }
    }
  },
  async created() {
    this.$watch(
      () => this.query,
      debounce((newVal, oldVal) => {
        this.$store.commit('setProjectFilter', {
          ...this.$store.state.projectFilter,
          query: newVal
        })
      }, 300)
    )


    this.$watch(() => this.filterStatus, async () => {
      store.commit("setProjectFilter", {
        ...store.state.projectFilter,
        status: this.filterStatus,
        currentPage: 1
      })
      await store.dispatch("loadProjects")
    }, { immediate: true })
  }
})
