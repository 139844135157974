import Vue from "vue"
import Vuex, { Store } from "vuex"
import { Bom, Project, logic } from "@ldvsg/shared"
import { ProjectFilter } from "./types"
import * as api from "./api"
import { profile, authConfig } from "@profile/config"
import options from "@/data/options"

Vue.use(Vuex)

const store: Store<any> = new Vuex.Store({
  strict: process.env.NODE_ENV == "development",
  state: {
    unsavedChanges: false,
    loadingProjects: false,
    projectFilter: {
      status: [],
      currentPage: 1,
      itemsPerPage: 10,
      marketNumber: 0,
      type: '',
      admin: false,
      query: ''
    } as ProjectFilter,
    projects: [] as Project[],
    totalProjects: 0,
    project: null as Project | null,
    bom: null as Bom | null,
    authUser: null as any,
    sidebarOpen: false,
  },
  getters: {
    fieldWidth(state): number | null {
      if (!state.project) return null
      const { width, roofingVariant } = state.project?.configuration
      if (!width || !roofingVariant) return null
      return logic.roofFieldWidth(width, logic.chevronCount(width, roofingVariant))
    },
    fieldDepth(state): number | null {
      if (!state.project) return null
      const { depth } = state.project.configuration
      if (!depth) return null
      return logic.roofFieldDepth(depth)
    },
    roofLength(state) {
      if (!state.project) return null
      const { depth, heightGutter, heightWall } = state.project?.configuration
      if (!depth || !heightGutter || !heightWall) return null
      return logic.roofLength(depth, heightGutter, heightWall)
    },
    chevrons(state) {
      if (!state.project) return null
      const { width, roofingVariant } = state.project?.configuration
      if (!width || !roofingVariant) return null
      return logic.chevronCount(width, roofingVariant)
    },
    discount(state) {
      return state.project?.payment.discount
    },
    heightGutter(state) {
      return state.project?.configuration.heightGutter;
    },
    heightWall(state) {
      return state.project?.configuration.heightWall
    },
    postOffsets(state) {
      return state.project?.configuration.postOffsets
    },
    triangleHeight(state) {
      if (!state.project) return null
      const { heightGutter, heightWall } = state.project.configuration
      if (!heightGutter || !heightWall) return null
      return heightWall - heightGutter
    },
    userRole: state => (role: string) => {
      if(state.authUser) return state.authUser.roles["Konfigurator"]?.includes(role)
      else setTimeout(() => { return store.getters.userRole(role) }, 300) //also schön ist das nicht
    },
    getRetrievalNumber: state => (type: string) => {
      if (type === "planning") return state.project?.retrievalNumberPlanning
      else return state.project?.retrievalNumberOrder
    },
    getOrderPlaced(state) {
      return state.project!.orderPlaced
    },
    projectDisabled(state) {
      return options.statusOptions.find(it => { return it.id === state.project?.status ?? ""})?.projectDisabled ?? false
    },
    basicConfig(state) {
      return {
        "width": state.project?.configuration.width,
        "depth": state.project?.configuration.depth
      }
    },
    awningDepth(state) { // Wird nur benötigt, um die Spannweite der Markisenstoffe zu berechnen, kann aber nicht ersetzt werden
      return state.project?.awning.variant === 'on_roof' ? state.project.configuration.depth! : state.project?.configuration.depth! - 170
    },
    sidebarOpen(state) {
      return state.sidebarOpen;
    }
  },
  mutations: {
    setAuthUser(state, user) {
      state.authUser = user
    },
    setUnsavedChanges(state, unsaved: boolean) {
      state.unsavedChanges = unsaved
    },
    setProjectFilter(state, filter: ProjectFilter) {
      state.projectFilter = filter
    },
    setProjects(state, params: { projects: Project[], totalProjects: number }) {
      state.projects = params.projects
      state.totalProjects = params.totalProjects
    },
    setProject(state, project: Project | null) {
      state.project = project
    },
    setBom(state, bom: Bom) {
      state.bom = bom
    },
    setSpecialDiscount(state, disc: number) {
      state.project!.payment.specialDiscount = disc
    },
    setDiscount(state, disc: number) {
      state.project!.payment.discount = disc
    },
    setSidebarOpen(state, open: boolean){
      state.sidebarOpen = open;
    },
  },
  actions: {
    async loadProjects(store) {
      //Admins können alle Projekte sehen, um normal nach Marktnummer zu filtern, die if-Verzweigung ausmachen :p
      if (store.state.authUser) {
        let isAdmin = store.getters.userRole('admin') || store.getters.userRole('ld_admin')
        store.commit('setProjectFilter', {
          ...store.state.projectFilter,
          admin:  isAdmin
        })
        if (profile === 'ldvsg') {
          let salesmanId = -1
          let managerId = -1
          if (!isAdmin) {
            salesmanId = store.state.authUser.salesmanId
            managerId = store.state.authUser.managerId
            if(!salesmanId) salesmanId = -1
            if(!managerId) managerId = -1
            store.commit("setProjectFilter", {
              ...store.state.projectFilter,
              salesmanId: salesmanId,
              managerId: managerId
            })
          }
        } else {
          let marketNumber = 0
          if (!isAdmin) {
            marketNumber = store.state.authUser.market_number
            if(!marketNumber) marketNumber = 0
            store.commit("setProjectFilter", {
              ...store.state.projectFilter,
              marketNumber: marketNumber
            })
          }
        }
        const result = await api.getProjects(store.state.projectFilter)
        store.commit("setProjects", { projects: result.items, totalProjects: result.totalCount })
      }
    },
    async loadProject(store, id) {
      store.commit("setProject", await api.getProject(id))
      store.dispatch("loadBom")
    },
    async deleteProject(store, id) {
      await api.deleteProject(id)
      store.dispatch("loadProjects")
    },
    async createProject(store) {
      const project = await api.createProject({})
      if (profile !== "ldvsg") {
        await api.updateProject({
          ...project,
          market: {
            marketNumber: store.state.authUser.market_number,
            type: profile
          }
        })
      } else {
        await api.updateProject({
          ...project,
          salesId: {
            salesmanId: store.state.authUser.salesManId ?? -1,
            managerId: store.state.authUser.groupId?? -1
          }
        })
      }
      store.commit("setProject", project)
      return project
    },
    async updateProject(store, project: Partial<Project>) {
      await api.updateProject(project)
      store.commit("setProject", project)
      store.dispatch("loadBom")
    },
    async updateVis(store, vis: any) {
      await api.updateVis(vis, store.state.project!.id)
    },
    async loadBom(store) {
      if (store.state.project == null) return
      const bom = await api.getBom(store.state.project.id)
      store.commit("setBom", bom)
    },
    async generatePDF(store, project: Project) {
      const res = await api.fetchAPI(`/api/projects/${project.id}/pdf`)
      return await res.blob()
    },
    async getPreview(store, type: string) {
      const res = await api.fetchAPI(`/api/projects/preview/${type}`)
      return await res.blob()
    },
    async updateArticles(store, data: string) {
      return await api.updateArticles(data)
    },
    async getArticles(store) {
      return await api.getArticles()
    },
    async getExcel(store) {
      if (store.state.project == null) return
      return await api.getExcel(store.state.project.id)
    },
    async requestSupport(store) {
      if (store.state.project == null) return
      return await api.requestSupport(store.state.project.id)
    },
    async requestOffer(store) {
      if (store.state.project == null) return
      return await api.requestOffer(store.state.project.id)
    },
    async getPromoCodes(store, all: boolean = false) {
      if (all) return await api.getAllPromoCodes()
      else return await api.getPromoCodes(profile)
    }
  }
})

export default store
