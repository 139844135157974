import Keecloak from "keecloak"
import Vue from "vue"
import store from './store'
import { authConfig } from "@profile/config"

const kcConfig = {
  url: authConfig.VUE_APP_KEYCLOAK_URL,
  realm: authConfig.VUE_APP_KEYCLOAK_REALM,
  clientId: "Konfigurator"
}

export const keycloak = new Keecloak(kcConfig)

const authInstance = new Vue({
  data: {
    loading: true,
    isAuthenticated: false,
    user: null as any
  },
  methods: {
    login() {
      return keycloak.login()
    },
    logout() {
      keycloak.logout()
    }
  },
  async created() {
    await keycloak.init()
    if (!keycloak.authenticated) await keycloak.login()

    this.user = keycloak.userInfo
    this.isAuthenticated = !!keycloak.authenticated
    this.loading = false

    store.commit("setAuthUser", this.user)
  }
})

export const AuthPlugin: Vue.PluginObject<any> = {
  install(Vue) {
    Vue.prototype.$auth = authInstance
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $auth: Auth
  }
}

interface Auth {
  loading: boolean
  isAuthenticated: boolean
  user: any
}

Object.assign(window, { keycloak })
