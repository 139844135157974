import Vue from "vue"

Vue.directive("disable-all", {
  inserted: function(el: any, binding) {
    if (!binding.value) return;
    const tags = ["input", "select", "button"];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      setTimeout(function() {
        const filteredNodes = [...nodes].filter((e: any) => ![...e.classList].includes("no-disable"))
        for (let i = 0; i < filteredNodes.length; i++) {
          filteredNodes[i].disabled = true;
          filteredNodes[i].tabIndex = -1;
        }
      }, 100)
    });
  }
})